import { Dispatch, FunctionComponent, SetStateAction } from "react";
import EditIcon from "../../vectors/EditIcon";
import { TextField } from "@o4c/plugin-components";
import { IMember } from "../../../interfaces/member.interface";
import { useTranslation } from "react-i18next";

interface IEditDialogBodyProps {
	currentMember: IMember;
	nicknameInput: string;
	setNicknameInput: Dispatch<SetStateAction<string>>;
}

const EditDialogBody: FunctionComponent<IEditDialogBodyProps> = ({
	currentMember,
	nicknameInput,
	setNicknameInput,
}) => {
	const { profilePicture, firstName, lastName, teams, groups } = currentMember;
	const name = `${firstName} ${lastName}`;
	const { t } = useTranslation();

	return (
		<div className="edit-body">
			<div className="basic">
				<div
					className="profile-image"
					style={{
						backgroundImage: `url(${profilePicture})`,
					}}>
					<div className="inner">
						<EditIcon variant="white" />
					</div>
				</div>
				<TextField
					disabled
					label={t("name")}
					name="example"
					required
					type="text"
					value={name}
				/>
			</div>
			<div className="text-groups">
				<TextField
					characterLimitFn={(max, current) => t("nickname tip")}
					label={t("nickname")}
					maxLength={50}
					name="nickname"
					value={nicknameInput}
					onChange={(e) => setNicknameInput(e.currentTarget.value)}
					placeholder={t("nickname")}
				/>
				<TextField
					disabled
					label="team"
					name="team"
					required
					type="text"
					value={teams[0]}
				/>
				<TextField
					disabled
					label={t("group")}
					name="group"
					required
					type="text"
					value={groups[0]}
				/>
			</div>
		</div>
	);
};

export default EditDialogBody;
