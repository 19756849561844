import { FunctionComponent } from "react";
import EditHeader from "./EditHeader";
import Schedules from "./Schedules";
import { useLocation, useParams } from "react-router-dom";

interface IEditScheduleProps {}

const EditSchedule: FunctionComponent<IEditScheduleProps> = () => {
	const { uid } = useParams<{ uid: string }>();
	let location = useLocation();
	let query = new URLSearchParams(location.search);
	const displayName = query.get("displayName");

	return (
		<div className="edit-schedule">
			<EditHeader displayName={displayName || "My"} />
			<Schedules uid={uid} />
		</div>
	);
};

export default EditSchedule;
