import { FunctionComponent, ReactNode } from "react";

interface IDialogWrapperProps {
	children: ReactNode;
	handleClose: () => void;
}

const DialogWrapper: FunctionComponent<IDialogWrapperProps> = ({
	children,
	handleClose,
}) => {
	return (
		<div className="dialog-wrapper" onClick={handleClose}>
			{children}
		</div>
	);
};

export default DialogWrapper;
