import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ScheduleHeader from "./ScheduleHeader";
import { ISchedule } from "../../interfaces/schedule.interface";
import { useAppSelector } from "../../store/hooks";
import * as Api from "../../services/Api";
import { IMember } from "../../interfaces/member.interface";
import Schedules from "./Schedules";

interface IMemberScheduleProps {}

const MemberSchedule: FunctionComponent<IMemberScheduleProps> = () => {
	const { uid } = useParams<{ uid: string }>();
	const [memberSchedule, setMemberSchedule] = useState<{
		member: IMember | null;
		schedules: ISchedule[];
	}>({ member: null, schedules: [] });

	const {
		config: { token, magazineId },
		member: {
			member: { uid: loggedUid, manager: isManager },
		},
	} = useAppSelector((state) => state);

	async function getSchedules(signal: AbortSignal) {
		try {
			const data = await Api.getMemberSchedule(token, magazineId, uid, signal);
			setMemberSchedule(data);
		} catch (error) {
			// TODO: Global handle error with redux
			console.error(error);
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		getSchedules(controller.signal);
		return () => {
			controller.abort();
		};
	}, []);

	const loggedManager = memberSchedule.member?.uid === loggedUid && isManager;

	return (
		<div className="edit-schedule member-schedule">
			{(memberSchedule.member && (
				<>
					<ScheduleHeader member={memberSchedule.member} />
					<Schedules
						schedules={memberSchedule.schedules}
						loggedManager={loggedManager}
					/>
				</>
			)) || <p>No member found</p>}
		</div>
	);
};

export default MemberSchedule;
