import {
	Dispatch,
	FunctionComponent,
	SetStateAction,
	useEffect,
	useState,
} from "react";
import { Dayjs } from "dayjs";
import { useAppSelector } from "../../store/hooks";
import { ISchedule } from "../../interfaces/schedule.interface";
import * as Api from "../../services/Api";
import { formatDate } from "../utils";
import { useTranslation } from "react-i18next";

interface ILocationButtonsProps {
	day: {
		weekday: string;
		date: Dayjs;
	};
	uid: string;
	timeslot: string;
	setSchedules: Dispatch<SetStateAction<ISchedule[]>>;
	schedule: ISchedule;
}

const LocationButtons: FunctionComponent<ILocationButtonsProps> = ({
	day,
	uid,
	timeslot,
	schedule,
}) => {
	const [buttonSchedule, setButtonSchedule] = useState<ISchedule | null>(
		schedule
	);
	const {
		token,
		magazineId,
		lang = "en",
		locs,
	} = useAppSelector((state) => state.config);

	const { t } = useTranslation()

	const isSelected = (location: string) => {
		return buttonSchedule && buttonSchedule.loc === location;
	};

	const hasSelected = (): boolean =>
		!!buttonSchedule && buttonSchedule.loc.length > 0;

	const handleClick = async (location: string) => {
		const newSchedule = await Api.updateOrCreateSchedule(
			token,
			magazineId,
			uid,
			formatDate(day.date),
			timeslot,
			location
		);
		setButtonSchedule(newSchedule);
	};

	useEffect(() => {
		setButtonSchedule(schedule);
	}, [schedule]);

	return (
		<div className={`${hasSelected() ? "hasSelected" : "noSelected"}`}>
			<div className="timeslot">{t(timeslot)}</div>
			<div className="locs">
				{locs.map((loc) => {
					const selected = isSelected(loc.id);
					return (
						<button
							className={selected ? "selected" : ""}
							key={loc.id}
							onClick={() => handleClick(loc.id)}>
							{
								//@ts-ignore
								loc[lang]
							}
						</button>
					);
				})}
			</div>
		</div>
	);
};

export default LocationButtons;
