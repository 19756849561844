import { FunctionComponent } from "react";

const EditIcon: FunctionComponent<{ variant?: string }> = ({ variant }) => {
	return (
		<div>
			<svg width={16} height={16} fill={variant ? variant : "#E37222"}>
				<path
					d="M13.3333 7.66002C13.1565 7.66002 12.987 7.73026 12.8619 7.85528C12.7369 7.98031 12.6667 8.14988 12.6667 8.32669V14C12.6667 14.1768 12.5964 14.3464 12.4714 14.4714C12.3464 14.5964 12.1768 14.6667 12 14.6667H2C1.82319 14.6667 1.65362 14.5964 1.5286 14.4714C1.40357 14.3464 1.33333 14.1768 1.33333 14V2.66669C1.33333 2.48988 1.40357 2.32031 1.5286 2.19528C1.65362 2.07026 1.82319 2.00002 2 2.00002H8.66667C8.84348 2.00002 9.01305 1.92978 9.13807 1.80476C9.2631 1.67973 9.33333 1.51016 9.33333 1.33335C9.33333 1.15654 9.2631 0.986973 9.13807 0.861949C9.01305 0.736925 8.84348 0.666687 8.66667 0.666687H2C1.46957 0.666687 0.960859 0.877401 0.585786 1.25247C0.210714 1.62755 0 2.13625 0 2.66669L0 14C0 14.5305 0.210714 15.0392 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H12C12.5304 16 13.0391 15.7893 13.4142 15.4142C13.7893 15.0392 14 14.5305 14 14V8.33335C14.0009 8.24525 13.9843 8.15784 13.9512 8.07618C13.9181 7.99453 13.8691 7.92025 13.8071 7.85763C13.7451 7.79502 13.6713 7.74532 13.59 7.7114C13.5087 7.67748 13.4214 7.66002 13.3333 7.66002Z"
					fill={variant ? variant : "#E37222"}
				/>
				<path
					d="M12.5467 2.03333C12.4833 1.97183 12.3984 1.93744 12.3101 1.93744C12.2217 1.93744 12.1368 1.97183 12.0734 2.03333L6.86672 7.24C6.83398 7.27171 6.8089 7.31047 6.79338 7.35333L5.84672 9.71333C5.82318 9.77406 5.81757 9.84027 5.83057 9.90409C5.84357 9.96791 5.87463 10.0267 5.92005 10.0733C5.96794 10.1168 6.02665 10.1465 6.08999 10.1594C6.15334 10.1723 6.21899 10.1679 6.28005 10.1467L8.66672 9.2C8.70958 9.18448 8.74833 9.1594 8.78005 9.12666L13.9867 3.92C14.0489 3.85805 14.0848 3.77444 14.0867 3.68666C14.0851 3.59686 14.0493 3.51105 13.9867 3.44666L12.5467 2.03333Z"
					fill={variant ? variant : "#E37222"}
				/>
				<path
					d="M15.6066 0.386686C15.3568 0.138351 15.0189 -0.0010376 14.6666 -0.0010376C14.3144 -0.0010376 13.9764 0.138351 13.7266 0.386686L13.02 1.09335C12.9881 1.12328 12.9627 1.15942 12.9453 1.19956C12.9279 1.23969 12.9189 1.28295 12.9189 1.32669C12.9189 1.37042 12.9279 1.41368 12.9453 1.45382C12.9627 1.49395 12.9881 1.53009 13.02 1.56002L14.4333 2.98002C14.4964 3.03956 14.5799 3.07272 14.6666 3.07272C14.7534 3.07272 14.8369 3.03956 14.9 2.98002L15.6066 2.26669C15.855 2.01687 15.9943 1.67893 15.9943 1.32669C15.9943 0.974438 15.855 0.636503 15.6066 0.386686Z"
					fill={variant ? variant : "#E37222"}
				/>
			</svg>
		</div>
	);
};

export default EditIcon;
