import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { FunctionComponent, ReactNode, useEffect, useState } from "react";

interface IMuiDLThmeProps {
	children: ReactNode;
}

const darkTheme = createTheme({
	palette: {
		mode: "dark",
	},
});
const lightTheme = createTheme({
	palette: {
		mode: "light",
	},
});

const MuiDLThme: FunctionComponent<IMuiDLThmeProps> = ({ children }) => {
	const [isDarkMode, setIsDarkMode] = useState(false);

	useEffect(() => {
		const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
		setIsDarkMode(mediaQuery.matches);

		const handleChange = (event: {
			matches: boolean | ((prevState: boolean) => boolean);
		}) => {
			setIsDarkMode(event.matches);
		};

		mediaQuery.addEventListener("change", handleChange);

		return () => {
			mediaQuery.removeEventListener("change", handleChange);
		};
	}, []);

	return (
		<>
			<ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</>
	);
};

export default MuiDLThme;
