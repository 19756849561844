import { FunctionComponent, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Api from "../../services/Api";
import { useAppSelector } from "../../store/hooks";

interface IFullProps {}

const Full: FunctionComponent<IFullProps> = ({}) => {
	const history = useHistory();
	const {
		config: { queryString, token, magazineId },
		member: { member },
	} = useAppSelector((state) => state);

	async function handleRedirectPath(signal?: AbortSignal) {
		try {
			if (!member.uid) return;
			const stateData = await Api.getState(
				+magazineId,
				member.uid,
				token ?? ""
			);
			if (!stateData || !stateData.scheduleOwnerUid)
				return history.push(`/all-schedules${queryString}`);
			const baseRoute = stateData.isEdit ? "edit-schedule" : "member-schedule";
			history.push(`/${baseRoute}/${stateData.scheduleOwnerUid}${queryString}`);
		} catch (error) {
			console.log(error);
			history.push(`/all-schedules${queryString}`);
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		handleRedirectPath(controller.signal);
		return () => {
			controller.abort();
		};
	}, [history]);

	return (
		<>
			<p>loading...</p>;
		</>
	);
};

export default Full;
