import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IMember } from "../../interfaces/member.interface";
import * as Api from "../../services/Api";
import { defaultMember } from "../../interfaces/defaults";

interface MemberStateType {
	member: IMember;
	getMemberLoading: boolean;
	getMemberError: boolean;
}

const initialState: MemberStateType = {
	member: defaultMember,
	getMemberError: false,
	getMemberLoading: true,
};

export const getMember = createAsyncThunk(
	"member/getMember",
	async (
		{
			token,
			magazineId,
			signal,
		}: {
			token: string;
			magazineId: string;
			signal: AbortSignal;
		},
		thunkApi
	) => {
		const response = await Api.getOrCreateUser(token, magazineId, signal);
		return response;
	}
);

export const memberSlice = createSlice({
	name: "member",
	initialState,
	reducers: {
		saveLoading(state) {
			state.getMemberLoading = true;
		},
	},
	extraReducers(builder) {
		builder.addCase(getMember.fulfilled, (state, action) => {
			state.member = action.payload;
			state.getMemberLoading = false;
			state.getMemberError = false;
		});
		builder.addCase(getMember.pending, (state) => {
			state.getMemberLoading = true;
		});
		builder.addCase(getMember.rejected, (state, action) => {
			state = initialState;
			state.getMemberLoading = false;
			state.getMemberError = true;
		});
	},
});

export default memberSlice.reducer;
