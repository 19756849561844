import { Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import DialogWrapper from "../../layout/DialogWrapper";
import SliderComponent from "../../layout/SliderComponent";
import { IMember } from "../../../interfaces/member.interface";
import * as Api from "../../../services/Api";
import { useAppSelector } from "../../../store/hooks";
import EditDialogHeader from "./EditDialogHeader";
import EditDialogBody from "./EditDialogBody";
import EditDialogFooter from "./EditDialogFooter";

interface IEditDialogProps {
	setOpenEditDialog: Dispatch<SetStateAction<boolean>>;
	setMembers: Dispatch<SetStateAction<IMember[]>>;
	setFilteredMembers: Dispatch<SetStateAction<IMember[]>>;
	openEditDialog: boolean;
	setOpenRemoveDialog: Dispatch<SetStateAction<boolean>>;
	currentMember: IMember;
}

const EditDialog: FunctionComponent<IEditDialogProps> = ({
	setOpenEditDialog,
	openEditDialog,
	setOpenRemoveDialog,
	currentMember,
	setMembers,
	setFilteredMembers,
}) => {
	const handleClose = () => setOpenEditDialog(false);
	const { nickname, uid } = currentMember;

	const [nicknameInput, setNicknameInput] = useState<string>(nickname || "");
	const { token, magazineId } = useAppSelector((state) => state.config);

	const handleClickSave = async () => {
		// TODO: handle error, will be handled globally after every thing is finished with redux
		if (nicknameInput != nickname) {
			const updatedMember = await Api.updateUserNickname(
				token,
				magazineId,
				uid,
				nicknameInput
			);
			setMembers((p) => {
				const updatedMembers = p!.filter((m) => m.uid !== currentMember.uid);
				updatedMembers.push(updatedMember);
				return updatedMembers;
			});
			setFilteredMembers((p) => {
				const updatedMembers = p!.filter((m) => m.uid !== currentMember.uid);
				updatedMembers.push(updatedMember);
				return updatedMembers;
			});
		}
		setOpenEditDialog(false);
	};

	return (
		<DialogWrapper handleClose={handleClose}>
			<div className="profile-edit-form" onClick={(e) => e.stopPropagation()}>
				<SliderComponent direction="up" isIn={openEditDialog}>
					<>
						<EditDialogHeader
							setOpenEditDialog={setOpenEditDialog}
							handleClose={handleClose}
						/>
						<EditDialogBody
							currentMember={currentMember}
							nicknameInput={nicknameInput}
							setNicknameInput={setNicknameInput}
						/>
						<EditDialogFooter
							setOpenRemoveDialog={setOpenRemoveDialog}
							setOpenEditDialog={setOpenEditDialog}
							handleClickSave={handleClickSave}
						/>
					</>
				</SliderComponent>
			</div>
		</DialogWrapper>
	);
};

export default EditDialog;
