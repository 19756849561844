import { Icon } from "@o4c/plugin-components";
import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { useTranslation } from "react-i18next";

interface IEditHeaderProps {
	displayName: string;
}

const EditHeader: FunctionComponent<IEditHeaderProps> = ({ displayName }) => {
	const history = useHistory();

	const { queryString } = useAppSelector((state) => state.config);
	const { t } = useTranslation()

	const headerText = displayName === "My" ? t(`editing ${displayName} schedule`) : t("editing schedule", { name: displayName })

	const handleHistory = () => {
		if (history.length > 1) {
			history.goBack();
			return;
		}
		history.push(`/all-schedules${queryString}`);
	};

	return (
		<div className="header">
			<Icon name="arrow-left" onClick={handleHistory} className="back-icon" />
			<div className="header-title">{headerText}</div>
		</div>
	);
};

export default EditHeader;
