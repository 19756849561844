import { Dialog } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import {
	Dispatch,
	FunctionComponent,
	SetStateAction,
	useState,
} from "react";
import { formatDate } from "../utils";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Icon } from "@o4c/plugin-components";
import MuiDLThme from "../layout/MuiDLTheme";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import "dayjs/locale/nl"

interface IDateActions {
	selectedDate: Dayjs;
	setSelectedDate: Dispatch<SetStateAction<Dayjs>>;
}

const DateActions: FunctionComponent<IDateActions> = ({
	selectedDate,
	setSelectedDate,
}) => {
	const today = dayjs();
	const tomorrow = dayjs().add(1, "day");
	const lang = useAppSelector(state => state.config.lang)

	const { t } = useTranslation();

	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const [calendarOpen, setCalendarOpen] = useState<boolean>(false);

	const baseButtons = [
		{ text: t("today") },
		{ text: t("tomorrow") },
		{ text: t("select date") },
	];

	const handleClick = (index: number) => {
		setSelectedIndex(index);
		if (index === 0) setSelectedDate(today);
		if (index === 1) setSelectedDate(tomorrow);
		if (index === 2) setCalendarOpen(true);
	};

	const handleChangeDate = (newValue: any) => {
		setSelectedDate(newValue);
		if (formatDate(newValue) === formatDate(today)) setSelectedIndex(0);
		if (formatDate(newValue) === formatDate(tomorrow)) setSelectedIndex(1);
		setCalendarOpen(false);
	};

	const handleClose = () => {
		if (formatDate(selectedDate) === formatDate(today)) setSelectedIndex(0);
		if (formatDate(selectedDate) === formatDate(tomorrow)) setSelectedIndex(1);
		setCalendarOpen(false);
	};

	const buttonText = (): string => {
		if (
			formatDate(selectedDate) === formatDate(today) ||
			formatDate(selectedDate) === formatDate(tomorrow)
		) {
			return t("select date");
		}

		return selectedIndex === 2 && selectedDate
			? dayjs(selectedDate).format("DD/MM")
			: t("select date");
	};

	const DateActionsContainer = () => {
		return (
			<div className="date-actions">
				{baseButtons.map((b, i) => {
					const selected: boolean = selectedIndex === i;
					return (
						<button
							key={b.text}
							className={selected ? "selected" : ""}
							onClick={() => handleClick(i)}>
							{selected && <Icon name="check" />}
							{i < 2 ? b.text : buttonText()}
						</button>
					);
				})}
				{selectedIndex === 2 && (
					<button className="selected" onClick={() => handleClick(2)}>
						<Icon name="schedule" size="small" />
					</button>
				)}
			</div>
		);
	};

	return (
		<>
			<DateActionsContainer />
			<MuiDLThme>
				<Dialog
					open={calendarOpen}
					onClose={handleClose}
					fullWidth={true}
					transitionDuration={500}>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang}>
						<DateCalendar
							value={selectedDate}
							onChange={(newValue: any) => handleChangeDate(newValue)}
							maxDate={dayjs().add(14, "day")}
							sx={{ width: "fit-content" }}
						/>
					</LocalizationProvider>
				</Dialog>
			</MuiDLThme>
		</>
	);
};

export default DateActions;
