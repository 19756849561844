import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { formatDate, getDays } from "../utils";
import { ISchedule } from "../../interfaces/schedule.interface";
import { Dayjs } from "dayjs";
import ScheduleCard from "./ScheduleCard";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { useTranslation } from "react-i18next";

interface ISchedulesProps {
	schedules: ISchedule[];
	loggedManager: boolean;
}

const Schedules: FunctionComponent<ISchedulesProps> = ({
	schedules,
	loggedManager,
}) => {
	const twoWeeks = getDays(14);

	const selectSchedules = (date: Dayjs): ISchedule[] =>
		schedules.filter((s) => s.date === formatDate(date)) || [];

	const { t } = useTranslation();

	const {
		member: {
			member: { uid },
		},
		config: { queryString },
	} = useAppSelector((state) => state);

	return (
		<div className="schedules-container">
			{loggedManager && (
				<div className="schedule-actions">
					<div className="links">
						<Link
							to={`/edit-schedule/${uid}${queryString}&displayName=My`}
							className="button">
							{t("edit my schedule")}
						</Link>
					</div>
				</div>
			)}
			{twoWeeks.map((m) => {
				return (
					<Fragment key={m.monthName}>
						<div className="month-name">{t(m.monthName)}</div>
						<div className="schedule-cards">
							{m.days.map((day, index) => (
								<Fragment key={index}>
									<ScheduleCard
										day={day}
										cardSchedules={selectSchedules(day.date)}
									/>
								</Fragment>
							))}
						</div>
					</Fragment>
				);
			})}
		</div>
	);
};

export default Schedules;
