import dayjs, { Dayjs } from "dayjs";
import { FC, FormEvent, useEffect, useState } from "react";
import { IMember } from "../../interfaces/member.interface";
import { ISchedule } from "../../interfaces/schedule.interface";
import * as Api from "../../services/Api";
import { useAppSelector } from "../../store/hooks";
import { formatDate, isSearchTextMatch } from "../utils";
import DateActions from "./DateActions";
import ManagerCard from "./ManagerCard";
import ScheduleActions from "./ScheduleActions";
import { useTranslation } from "react-i18next";

interface IAllSchedules { }

const AllSchedules: FC<IAllSchedules> = () => {
	const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
	const [searchText, setSearchText] = useState<string>("");
	const [allSchedules, setAllSchedules] = useState<
		{ member: IMember; schedules: ISchedule[] }[]
	>([]);
	const [filteredAllSchedules, setFilteredAllSchedules] = useState<
		{ member: IMember; schedules: ISchedule[] }[]
	>([]);
	const {
		member,
		config: { token, magazineId },
	} = useAppSelector((state) => state);
	const { t } = useTranslation()

	async function getAllSchedules(signal: AbortSignal) {
		try {
			const schedules = await Api.getSchedulesByDate(
				token,
				magazineId,
				formatDate(selectedDate),
				signal
			);
			schedules.sort((a, b) => +a.member.groups[0] - +b.member.groups[0])
			setAllSchedules(schedules);
			setFilteredAllSchedules(schedules);
		} catch (error) {
			// TODO: Global handle error with redux
			console.error(error);
		}
	}

	const handleSearch = (
		e: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>
	) => {
		setSearchText(e.currentTarget.value);
		if (!e.currentTarget.value) return setFilteredAllSchedules(allSchedules);
		const newFilteredSchedules = allSchedules.filter((s) =>
			isSearchTextMatch(s.member, e.currentTarget.value)
		);
		setFilteredAllSchedules(newFilteredSchedules);
	};

	useEffect(() => {
		if (!member.member.uid) return;
		const controller = new AbortController();
		getAllSchedules(controller.signal);
		return () => {
			controller.abort();
		};
	}, [selectedDate, member.member]);

	return (
		<div className="all-schedules">
			<DateActions
				selectedDate={selectedDate}
				setSelectedDate={setSelectedDate}
			/>
			<div className="schedules">
				<ScheduleActions searchText={searchText} handleSearch={handleSearch} />
				<div className="manager-cards">
					{(!member.getMemberLoading &&
						filteredAllSchedules &&
						filteredAllSchedules.length &&
						filteredAllSchedules.map(({ member, schedules: mSchedules }, i) => (
							<ManagerCard key={i} member={member} mSchedules={mSchedules} />
						))) || <p className="text-primary">{t("no schedule found")}</p>}
				</div>
			</div>
		</div>
	);
};

export default AllSchedules;
