import { Button, Icon } from "@o4c/plugin-components";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface IEditDialogFooterProps {
	setOpenRemoveDialog: Dispatch<SetStateAction<boolean>>;
	setOpenEditDialog: Dispatch<SetStateAction<boolean>>;
	handleClickSave: () => void;
}

const EditDialogFooter: FunctionComponent<IEditDialogFooterProps> = ({
	setOpenRemoveDialog,
	setOpenEditDialog,
	handleClickSave,
}) => {
	const { t } = useTranslation();
	return (
		<div className="edit-footer">
			<Button
				display="block"
				size="default"
				type="invisible"
				onClick={() => setOpenRemoveDialog(true)}>
				<Icon leading name="delete" />
				<div className="button-text">{t("remove")}</div>
			</Button>
			<Button
				type="outline"
				display="block"
				onClick={() => setOpenEditDialog(false)}>
				{t("cancel")}
			</Button>
			<Button
				color="positive"
				display="block"
				type="solid"
				onClick={handleClickSave}>
				{t("save")}
			</Button>
		</div>
	);
};

export default EditDialogFooter;
