import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { Button, Icon } from "@o4c/plugin-components";
import { Dialog } from "@mui/material";
import { IMember } from "../../../interfaces/member.interface";
import * as Api from "../../../services/Api";
import { useAppSelector } from "../../../store/hooks";
import { useTranslation } from "react-i18next";

interface IRemoveDialogProps {
	setOpenRemoveDialog: Dispatch<SetStateAction<boolean>>;
	openRemoveDialog: boolean;
	currentMember: IMember;
	setOpenEditDialog: Dispatch<SetStateAction<boolean>>;
	setMembers: Dispatch<SetStateAction<IMember[]>>;
	setFilteredMembers: Dispatch<SetStateAction<IMember[]>>;
}

const RemoveDialog: FunctionComponent<IRemoveDialogProps> = ({
	setOpenRemoveDialog,
	openRemoveDialog,
	currentMember,
	setOpenEditDialog,
	setMembers,
	setFilteredMembers,
}) => {
	const { token, magazineId } = useAppSelector((state) => state.config);
	const { t } = useTranslation();

	const handleClose = () => setOpenRemoveDialog(false);
	const { firstName, lastName, groups, teams, profilePicture, uid } =
		currentMember;
	const name = `${firstName} ${lastName}`;

	const handleClickRemove = async () => {
		await Api.removeUserByUid(token, magazineId, uid);
		setMembers((p) => {
			const updatedMembers = p!.filter((m) => m.uid !== currentMember.uid);
			return updatedMembers;
		});
		setFilteredMembers((p) => {
			const updatedMembers = p!.filter((m) => m.uid !== currentMember.uid);
			return updatedMembers;
		});
		setOpenEditDialog(false);
		setOpenRemoveDialog(false);
	};

	return (
		<Dialog open={openRemoveDialog} onClose={handleClose} fullWidth={true}>
			<div
				className="profile-edit-form remove"
				onClick={(e) => e.stopPropagation()}>
				<div className="edit-header">
					<div className="edit-title">{t("remove manager")}</div>
					<div onClick={() => setOpenRemoveDialog(false)}>
						<Icon
							name="close"
							onClick={handleClose}
							style={{ color: "#e37222" }}
						/>
					</div>
				</div>
				<div className="edit-body">
					<div className="basic">
						<div
							className="profile-image"
							style={{
								backgroundImage: `url(${profilePicture})`,
							}}></div>
						<div className="profile-detail">
							<div className="name">{name}</div>
							<div className="group-team">
								<div className="group text-upper">{groups[0]}</div>
								<div className="dash-grey">-</div>
								<div className="team text-upper">{teams[0]}</div>
							</div>
						</div>
					</div>
				</div>
				<div className="edit-footer">
					<Button
						type="outline"
						display="block"
						onClick={() => setOpenRemoveDialog(false)}>
						{t("cancel")}
					</Button>
					<Button
						color="negative"
						display="block"
						type="solid"
						onClick={handleClickRemove}>
						{t("remove")}
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default RemoveDialog;
