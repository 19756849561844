import dayjs from "dayjs";
import { FunctionComponent } from "react";
import { ISchedule } from "../../interfaces/schedule.interface";
import { useAppSelector } from "../../store/hooks";
import { getLocation } from "../utils";
import { useTranslation } from "react-i18next";

interface IScheduleCardProps {
	day: {
		weekday: string;
		date: dayjs.Dayjs;
	};
	cardSchedules: ISchedule[];
}

const ScheduleCard: FunctionComponent<IScheduleCardProps> = ({
	day,
	cardSchedules,
}) => {
	const { lang = "en", locs } = useAppSelector((state) => state.config);
	const { t } = useTranslation();

	return (
		<div className="schedule-card member-schedule-card">
			<div className="left">
				<div className="weekday">{t(day.weekday)}</div>
				<div className="date">{dayjs(day.date.clone()).format("DD")}</div>
			</div>
			<div className="middle">
				<div className="timeslot">{t("morning")}</div>
				<div className="location">
					{getLocation("morning", cardSchedules, lang, locs)}
				</div>
			</div>
			<div className="right">
				<div className="timeslot">{t("afternoon")}</div>
				<div className="location">
					{getLocation("afternoon", cardSchedules, lang, locs)}
				</div>
			</div>
		</div>
	);
};

export default ScheduleCard;
