import { IMember } from "./member.interface";

export const defaultMember: IMember = {
	_id: "",
	uid: "",
	magazineId: 0,
	manager: false,
	profileManager: false,
	firstName: "",
	lastName: "",
	email: "",
	profilePicture: "",
	group: [],
	groups: [],
	teams: [],
	nickname: "",
};
