import { Icon, TextField } from "@o4c/plugin-components";
import { FunctionComponent, useState, useEffect, FormEvent } from "react";
import { useHistory } from "react-router-dom";
import ProfileCard from "./ProfileCard";
import EditDialog from "./editDialog/EditDialog";
import RemoveDialog from "./removeDialog/RemoveDialog";
import { useAppSelector } from "../../store/hooks";
import * as Api from "../../services/Api";
import { IMember } from "../../interfaces/member.interface";
import { isSearchTextMatch } from "../utils";
import { useTranslation } from "react-i18next";

interface IEditScheduleProps { }

const EditSchedule: FunctionComponent<IEditScheduleProps> = () => {
	const history = useHistory();
	const [searchText, setSearchText] = useState<string>("");
	const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
	const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false);
	const [currentMember, setCurrentMember] = useState<IMember | null>(null);
	const [members, setMembers] = useState<IMember[]>([]);
	const [filteredMembers, setFilteredMembers] = useState<IMember[]>([]);
	const { t } = useTranslation();

	const {
		config: { token, magazineId },
		member: { member },
	} = useAppSelector((state) => state);

	async function getMembers(signal: AbortSignal) {
		try {
			const members = await Api.getAllMembers(token, magazineId, signal);
			setMembers(members || []);
			setFilteredMembers(members || []);
		} catch (error) {
			// TODO: Global handle error with redux
			console.error(error);
		}
	}

	const { profileManager } = member;

	const handleClickEditButton = (member: IMember) => {
		setOpenEditDialog(true);
		setCurrentMember(member);
	};

	const handleSearch = (
		e: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>
	) => {
		setSearchText(e.currentTarget.value);
		if (!e.currentTarget.value) return setFilteredMembers(members);
		const newFilteredSchedules = members.filter((m) =>
			isSearchTextMatch(m, e.currentTarget.value)
		);
		setFilteredMembers(newFilteredSchedules);
	};

	useEffect(() => {
		if (!profileManager) return;
		const controller = new AbortController();
		getMembers(controller.signal);
		return () => {
			controller.abort();
		};
	}, []);

	return (
		(profileManager && (
			<div className="edit-schedule">
				<div className="header">
					<Icon
						name="arrow-left"
						onClick={() => history.goBack()}
						className="back-icon"
					/>
					<div className="header-title">{t("manage profiles")}</div>
				</div>
				<div className="profiles-container">
					<div className="search-container">
						<TextField
							label={t("search")}
							name="examp"
							placeholder={t("search place holder")}
							type="text"
							size={100}
							value={searchText}
							onChange={(e) => handleSearch(e)}
							icon={<Icon name="find-shift" className="cursor" />}
						/>
					</div>
					{(filteredMembers &&
						filteredMembers.length &&
						filteredMembers.map((member, index) => (
							<ProfileCard
								key={index}
								member={member}
								handleClickEditButton={handleClickEditButton}
							/>
						))) || <p className="text-primary">{t("no manager")}</p>}
				</div>

				{openEditDialog && (
					<EditDialog
						setOpenEditDialog={setOpenEditDialog}
						openEditDialog={openEditDialog}
						setOpenRemoveDialog={setOpenRemoveDialog}
						currentMember={currentMember!}
						setMembers={setMembers}
						setFilteredMembers={setFilteredMembers}
					/>
				)}
				{openRemoveDialog && (
					<RemoveDialog
						setOpenRemoveDialog={setOpenRemoveDialog}
						openRemoveDialog={openRemoveDialog}
						currentMember={currentMember!}
						setMembers={setMembers}
						setFilteredMembers={setFilteredMembers}
						setOpenEditDialog={setOpenEditDialog}
					/>
				)}
			</div>
		)) || <p>you don't have access to this page</p>
	);
};

export default EditSchedule;
