import { Icon, TextField } from "@o4c/plugin-components";
import { Dispatch, FormEvent, FunctionComponent, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { useTranslation } from "react-i18next";

interface IScheduleActions {
	searchText: string;
	handleSearch: (
		e: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>
	) => void;
}

const ScheduleActions: FunctionComponent<IScheduleActions> = ({
	searchText,
	handleSearch,
}) => {
	const {
		member: {
			member: { manager, profileManager, uid },
		},
	} = useAppSelector((state) => state);

	const { t } = useTranslation();

	const { queryString } = useAppSelector((state) => state.config);

	return (
		<div className="schedule-actions">
			{(manager || profileManager) && (
				<div className="links">
					{manager && (
						<Link
							to={`/edit-schedule/${uid}${queryString}&displayName=My`}
							className="button">
							{t("edit my schedule")}
						</Link>
					)}
					{profileManager && (
						<Link to={`/manage-profiles${queryString}`} className="button">
							{t("manage profiles")}
						</Link>
					)}
				</div>
			)}
			<TextField
				label={t("search")}
				name="examp"
				placeholder={t("search place holder")}
				type="text"
				value={searchText}
				onChange={(e) => handleSearch(e)}
				icon={<Icon name="find-shift" />}
			/>
		</div>
	);
};

export default ScheduleActions;
