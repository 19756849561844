import { FunctionComponent } from "react";
import EditIcon from "../vectors/EditIcon";
import { Link, useHistory } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { IMember } from "../../interfaces/member.interface";
import { ISchedule } from "../../interfaces/schedule.interface";
import {
	getFullName,
	getGroupType,
	getLocation,
	haveCommonElements,
} from "../utils";
import { Button } from "@o4c/plugin-components";
import ProfileImage from "../common/ProfileImage";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface IManagerCardProps {
	member: IMember;
	mSchedules: ISchedule[];
}

const ManagerCard: FunctionComponent<IManagerCardProps> = ({
	member,
	mSchedules,
}) => {
	const {
		config: { queryString, lang = "en", locs },
		member: { member: loggedinMember },
	} = useAppSelector((state) => state);

	const history = useHistory();
	const { t } = useTranslation();

	const {
		firstName,
		lastName,
		profilePicture,
		groups,
		teams,
		nickname,
		uid,
		group: cardOwnerGroup,
	} = member;
	const fullName = getFullName(firstName, lastName) || t("name is missing");
	const name = nickname ? nickname : fullName;
	const displayName = loggedinMember.uid === uid ? "My" : name;
	const same = groups.includes(loggedinMember.groups[0]) && teams.includes(loggedinMember.teams[0]);
	const canEdit =
		(haveCommonElements(loggedinMember.teams.slice(1), teams) || // for multiple teams structure
			(!loggedinMember.teams.length && !teams.length) || loggedinMember.teams.includes("all")) && // for one team structure
		loggedinMember.manager;

	const handleClickEdit = (e: MouseEvent) => {
		e.preventDefault();
		history.push(
			`/edit-schedule/${uid}${queryString}&displayName=${displayName}`
		);
	};

	return (
		<Link to={`/member-schedule/${uid}${queryString}`} className="cursor">
			<div className="manager-card">
				<div className="top">
					<ProfileImage imageUrl={profilePicture} altText="" />
					<div className="profile-detail">
						<div className="name">{name}</div>
						<div className="group-team">
							<div className={classNames({ "group": true, "text-upper": true, "same": same, "notsame": !same })}>
								{t(getGroupType(loggedinMember.group?.[0]))} {groups[0]}
							</div>
							{!same && <div className="dash-grey">-</div>}
							<div className="team text-upper">
								{teams[0] ?? cardOwnerGroup[0]}
							</div>
						</div>
					</div>
					{canEdit && (
						<Button
							type="invisible"
							display="inline"
							onClick={(e: MouseEvent) => handleClickEdit(e)}
							className="edit-icon">
							<EditIcon />
						</Button>
					)}
				</div>
				<div className="bottom">
					<div className="left">
						<div className="timeslot text-primary text-upper">
							{t("morning")}
						</div>
						<div className="location">
							{getLocation("morning", mSchedules, lang, locs)}
						</div>
					</div>
					<div className="right">
						<div className="timeslot text-primary text-upper">
							{t("afternoon")}
						</div>
						<div className="location">
							{getLocation("afternoon", mSchedules, lang, locs)}
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default ManagerCard;
