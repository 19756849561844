import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { Icon } from "@o4c/plugin-components";
import { useTranslation } from "react-i18next";

interface IEditDialogHeaderProps {
	setOpenEditDialog: Dispatch<SetStateAction<boolean>>;
	handleClose: () => void;
}

const EditDialogHeader: FunctionComponent<IEditDialogHeaderProps> = ({
	setOpenEditDialog,
	handleClose,
}) => {
	const { t } = useTranslation()

	return (
		<div className="edit-header">
			<div className="edit-title">{t("edit profile")}</div>
			<div onClick={() => setOpenEditDialog(false)}>
				<Icon name="close" onClick={handleClose} style={{ color: "#e37222" }} />
			</div>
		</div>
	);
};

export default EditDialogHeader;
