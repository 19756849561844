import axios from "axios";
import { IState, MagazineInterface } from "../interfaces/config";
import { IMember } from "../interfaces/member.interface";
import { ISchedule } from "../interfaces/schedule.interface";

function paramValidator(token: string, magazineId: string | number) {
	if (!token) throw new Error("no token");
	if (!magazineId) throw new Error("no magazineId");
}

export const getMagazine = async (
	token: string,
	magazineId: string,
	signal: AbortSignal
): Promise<MagazineInterface> => {
	try {
		paramValidator(token, magazineId);
		const url = `/api/config/${magazineId}`;
		const headers = {
			"Content-Type": "application/json",
			"X-Magazine-Id": magazineId,
			Authorization: token,
		};
		const { data } = await axios.get(url, { headers, signal });
		return data;
	} catch (error) {
		throw Error(`get magazine failed, ${error}`);
	}
};

export const getOrCreateUser = async (
	token: string,
	magazineId: string,
	signal: AbortSignal
): Promise<IMember> => {
	try {
		paramValidator(token, magazineId);
		const url = `/api/members`;
		const headers = {
			"Content-Type": "application/json",
			"X-Magazine-Id": magazineId,
			Authorization: token,
		};
		const { data } = await axios.post(url, {}, { headers, signal });
		return data;
	} catch (error) {
		throw Error(`get user failed, ${error}`);
	}
};

export const getAllMembers = async (
	token: string,
	magazineId: string,
	signal: AbortSignal
): Promise<IMember[]> => {
	try {
		paramValidator(token, magazineId);
		const url = `/api/members`;
		const headers = {
			"Content-Type": "application/json",
			"X-Magazine-Id": magazineId,
			Authorization: token,
		};
		const { data } = await axios.get(url, { headers, signal });
		return data;
	} catch (error) {
		throw Error(`get users failed, ${error}`);
	}
};

export const getSchedulesByDate = async (
	token: string,
	magazineId: string,
	selectedDate: string,
	signal: AbortSignal
): Promise<{ member: IMember; schedules: ISchedule[] }[]> => {
	try {
		paramValidator(token, magazineId);
		const url = `/api/members/all/${selectedDate}`;
		const headers = {
			"Content-Type": "application/json",
			"X-Magazine-Id": magazineId,
			Authorization: token,
		};
		const { data } = await axios.get(url, { headers, signal });
		return data;
	} catch (error) {
		throw Error(`get schedules by date failed, ${error}`);
	}
};

export const getMemberSchedule = async (
	token: string,
	magazineId: string,
	uid: string,
	signal: AbortSignal
): Promise<{ member: IMember; schedules: ISchedule[] }> => {
	try {
		paramValidator(token, magazineId);
		const url = `/api/members/${uid}`;
		const headers = {
			"Content-Type": "application/json",
			"X-Magazine-Id": magazineId,
			Authorization: token,
		};
		const { data } = await axios.get(url, { headers, signal });
		return data;
	} catch (error) {
		throw Error(`get schedules for member ${uid} failed, ${error}`);
	}
};

export const getTodaysScheduleByTeam = async (
	token: string,
	magazineId: string,
	teams: string[],
	signal: AbortSignal
): Promise<{ member: IMember; schedules: ISchedule[] }[]> => {
	try {
		paramValidator(token, magazineId);
		const url = `/api/members/all/today`;
		const headers = {
			"Content-Type": "application/json",
			"X-Magazine-Id": magazineId,
			Authorization: token,
		};
		const { data } = await axios.post(
			url,
			{ teams: teams.slice(0, 1) },
			{ headers, signal }
		);
		return data;
	} catch (error) {
		throw Error(`get schedules by team failed, ${error}`);
	}
};

export const getSchedulesByUid = async (
	token: string,
	magazineId: string,
	uid: string,
	signal: AbortSignal
): Promise<ISchedule[]> => {
	try {
		paramValidator(token, magazineId);
		const url = `/api/schedule/${uid}`;
		const headers = {
			"Content-Type": "application/json",
			"X-Magazine-Id": magazineId,
			Authorization: token,
		};
		const { data } = await axios.get(url, { headers, signal });
		return data;
	} catch (error) {
		throw Error(`get schedules by uid failed, ${error}`);
	}
};

export const updateUserNickname = async (
	token: string,
	magazineId: string,
	uid: string,
	nickname: string,
	signal?: AbortSignal
): Promise<IMember> => {
	try {
		paramValidator(token, magazineId);
		const url = `/api/members`;
		const headers = {
			"Content-Type": "application/json",
			"X-Magazine-Id": magazineId,
			Authorization: token,
		};
		const { data } = await axios.put(
			url,
			{ nickname, uid },
			{ headers, signal }
		);
		return data;
	} catch (error) {
		throw Error(`update user nickname failed, ${error}`);
	}
};

export const removeUserByUid = async (
	token: string,
	magazineId: string,
	uid: string,
	signal?: AbortSignal
): Promise<IMember> => {
	try {
		paramValidator(token, magazineId);
		const url = `/api/members/${uid}`;
		const headers = {
			"Content-Type": "application/json",
			"X-Magazine-Id": magazineId,
			Authorization: token,
		};
		const { data } = await axios.delete(url, { headers, signal });
		return data;
	} catch (error) {
		throw Error(`delete user failed, ${error}`);
	}
};

export const updateOrCreateSchedule = async (
	token: string,
	magazineId: string,
	uid: string,
	date: string,
	timeslot: string,
	loc: string
): Promise<ISchedule> => {
	try {
		paramValidator(token, magazineId);
		const url = `/api/schedule`;
		const headers = {
			"Content-Type": "application/json",
			"X-Magazine-Id": magazineId,
			Authorization: token,
		};
		const { data } = await axios.post(
			url,
			{
				magazineId,
				uid,
				date,
				timeslot,
				loc,
			},
			{ headers }
		);
		return data;
	} catch (error) {
		throw Error(`update or create schedule failed, ${error}`);
	}
};

// state services
export const saveState = async (
	magazineId: number,
	isEdit: boolean,
	scheduleOwnerUid: string,
	loggedMemberUid: string
): Promise<IState> => {
	try {
		const url = `/api/state`;
		return await axios.post(url, {
			magazineId,
			isEdit,
			scheduleOwnerUid,
			loggedMemberUid,
		});
	} catch (error) {
		throw Error(`save state failed, ${error}`);
	}
};

export const getState = async (
	magazineId: number,
	loggedMemberUid: string,
	token: string
): Promise<IState | null> => {
	try {
		paramValidator(token, magazineId);
		const url = `/api/state/${magazineId}/${loggedMemberUid}`;
		const { data } = await axios.delete(url);
		return data;
	} catch (error) {
		throw Error(`get state failed, ${error}`);
	}
};