import dayjs from "dayjs";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { ISchedule } from "../../interfaces/schedule.interface";
import LocationButtons from "./LocationButtons";
import { useTranslation } from "react-i18next";

interface IEditScheduleCardProps {
	day: {
		weekday: string;
		date: dayjs.Dayjs;
	};
	uid: string;
	setSchedules: Dispatch<SetStateAction<ISchedule[]>>;
	cardSchedules: ISchedule[];
}

const EditScheduleCard: FunctionComponent<IEditScheduleCardProps> = ({
	day,
	uid,
	setSchedules,
	cardSchedules,
}) => {
	const selectScheduleForTimeslot = (timeslot: string) =>
		cardSchedules.filter((s) => s.timeslot === timeslot)[0];

	const { t } = useTranslation();
	return (
		<div className="schedule-card">
			<div className="left">
				<div className="weekday">{t(day.weekday)}</div>
				<div className="date">{dayjs(day.date.clone()).format("DD")}</div>
			</div>
			<div className="right">
				<div className="top">
					<LocationButtons
						day={day}
						timeslot={"morning"}
						uid={uid}
						schedule={selectScheduleForTimeslot("morning")}
						setSchedules={setSchedules}
					/>
				</div>
				<div className="bottom">
					<LocationButtons
						day={day}
						timeslot={"afternoon"}
						uid={uid}
						schedule={selectScheduleForTimeslot("afternoon")}
						setSchedules={setSchedules}
					/>
				</div>
			</div>
		</div>
	);
};

export default EditScheduleCard;
