import { FunctionComponent } from "react";
import EditIcon from "../vectors/EditIcon";
import { IMember } from "../../interfaces/member.interface";
import { getFullName, getGroupType } from "../utils";
import ProfileImage from "../common/ProfileImage";
import { useTranslation } from "react-i18next";

interface IProfileCardProps {
	member: IMember;
	handleClickEditButton: (member: IMember) => void;
}

const ProfileCard: FunctionComponent<IProfileCardProps> = ({
	member,
	handleClickEditButton,
}) => {
	const { t } = useTranslation();
	const {
		firstName,
		lastName,
		profilePicture,
		groups,
		teams,
		nickname,
		group,
	} = member;
	const fullName = getFullName(firstName, lastName) || t("name is missing");
	const name = nickname ? nickname : fullName;

	return (
		<div
			className="manager-card cursor"
			onClick={() => handleClickEditButton(member)}>
			<div className="top">
				<ProfileImage imageUrl={profilePicture} altText="" />
				<div className="profile-detail">
					<div className="name">{name}</div>
					<div className="group-team">
						<div className="group text-upper">
							{t(getGroupType(group?.[0]))} {groups?.[0]}
						</div>
						<div className="dash-grey">-</div>
						<div className="team text-upper">{teams?.[0]}</div>
					</div>
				</div>
				<EditIcon />
			</div>
		</div>
	);
};

export default ProfileCard;
