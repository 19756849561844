import { FunctionComponent } from "react";
import { IMember } from "../../interfaces/member.interface";
import { ISchedule } from "../../interfaces/schedule.interface";
import { useAppSelector } from "../../store/hooks";
import {
	getFullName,
	getGroupType,
	getLocation
} from "../utils";
import ProfileImage from "../common/ProfileImage";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface IManagerCardProps {
	member: IMember;
	mSchedules: ISchedule[];
	handleClick: (isEdit: boolean, scheduleOwnerUid: string) => void;
}

const ManagerCard: FunctionComponent<IManagerCardProps> = ({
	member,
	mSchedules,
	handleClick,
}) => {
	const {
		member: { member: loggedMember },
		config: { lang = "en", locs },
	} = useAppSelector((state) => state);

	const { t } = useTranslation();

	const { firstName, lastName, profilePicture, groups, nickname, uid } =
		member;
	const fullName = getFullName(firstName, lastName) || t("name is missing");
	const name = nickname ? nickname : fullName;
	const same = groups.includes(loggedMember.groups[0])

	return (
		<div className="manager-card" onClick={() => handleClick(false, uid)}>
			<div className="top">
				<ProfileImage imageUrl={profilePicture} altText="" />
				<div className="profile-detail">
					<div className="name">{name}</div>
					<div className="group-team">
						<div className={classNames({ "group": true, "text-upper": true, "same": same, "notsame": !same })}>
							{t(getGroupType(loggedMember.group?.[0]))} {groups[0]}
						</div>
					</div>
				</div>
			</div>
			<div className="bottom">
				<div className="left">
					<div className="timeslot text-primary text-upper">{t("morning")}</div>
					<div className="location">
						{getLocation("morning", mSchedules, lang, locs)}
					</div>
				</div>
				<div className="right">
					<div className="timeslot text-primary text-upper">
						{t("afternoon")}
					</div>
					<div className="location">
						{getLocation("afternoon", mSchedules, lang, locs)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ManagerCard;
