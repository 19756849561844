import { Icon } from "@o4c/plugin-components";
import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { IMember } from "../../interfaces/member.interface";
import { useAppSelector } from "../../store/hooks";
import ProfileImage from "../common/ProfileImage";

interface IScheduleHeaderProps {
	member: IMember;
}

const ScheduleHeader: FunctionComponent<IScheduleHeaderProps> = ({
	member,
}) => {
	const history = useHistory();
	const {
		member: { member: loggedinMember },
		config: { queryString },
	} = useAppSelector((state) => state);

	const { firstName, lastName, profilePicture, nickname, uid } = member;
	const name = nickname ? nickname : `${firstName} ${lastName}`;
	const displayName = loggedinMember.uid === uid ? "Me" : name;

	const handleHistory = () => {
		if (history.length > 1) {
			history.goBack();
			return;
		}
		history.push(`/all-schedules${queryString}`);
	};

	return (
		<div className="header">
			<Icon name="arrow-left" onClick={handleHistory} className="back-icon" />
			<div className="header-title">{displayName}</div>
			<ProfileImage imageUrl={profilePicture} altText="" />
		</div>
	);
};

export default ScheduleHeader;
