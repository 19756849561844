import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ILoc, MagazineInterface } from "../../interfaces/config";
import * as Api from "../../services/Api";
import { getMember } from "./memberSlice";

interface ConfigStateType {
	magazine: MagazineInterface;
	loading: boolean;
	token: string;
	lang: string;
	queryString: string;
	magazineId: string;
	universalLink: string;
	locs: ILoc[];
}

const initialState: ConfigStateType = {
	loading: true,
	magazine: {
		clientName: "",
		publicKey: "",
		magazineId: "",
		config: {},
	},
	token: "",
	lang: "en",
	queryString: "",
	magazineId: "",
	locs: [],
	universalLink: "",
};

export const getConfig = createAsyncThunk(
	"config/getConfig",
	async (
		{
			token,
			magazineId,
			signal,
		}: {
			token: string;
			magazineId: string;
			signal: AbortSignal;
		},
		thunkApi
	) => {
		const response = await Api.getMagazine(token, magazineId, signal);
		return response;
	}
);

export const configSlice = createSlice({
	name: "config",
	initialState,
	reducers: {
		saveBasicConfig(state, action) {
			const {
				token = "",
				queryString = "",
				magazineId = "",
				lang = "en",
			} = action.payload;
			state.token = token;
			state.queryString = queryString;
			state.magazineId = magazineId;
			state.lang = lang;
		},
	},
	extraReducers(builder) {
		builder.addCase(getConfig.fulfilled, (state, action) => {
			state.magazine = action.payload;
			state.loading = false;
		});
		builder.addCase(getMember.fulfilled, (state, action) => {
			const config =
				state.magazine.config[action.payload?.group[0] || "Inflight"];
			const { locs, universalLink } = config.toJSON ? config.toJSON() : config;
			state.universalLink = universalLink;
			state.locs = locs;
		});
		builder.addCase(getConfig.pending, (state) => {
			state.magazine = initialState.magazine;
			state.loading = true;
		});
		builder.addCase(getConfig.rejected, (state, action) => {
			state.magazine = initialState.magazine;
			state.loading = false;
		});
	},
});

export const { saveBasicConfig } = configSlice.actions;

export default configSlice.reducer;
