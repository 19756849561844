import dayjs, { Dayjs } from "dayjs";
import { IMember } from "../interfaces/member.interface";
import { ISchedule } from "../interfaces/schedule.interface";
import { ILoc } from "../interfaces/config";

export const formatDate = (date: Dayjs) => date.format("YYYY-MM-DD");

interface IResult {
	monthName: string;
	days: { weekday: string; date: Dayjs }[];
}

export function getDays(daysAhead: number) {
	const currentDate = dayjs();

	const result: IResult[] = [
		{
			monthName: currentDate.clone().format("MMMM"),
			days: [],
		},
		{
			monthName: currentDate.clone().add(1, "months").format("MMMM"),
			days: [],
		},
	];

	for (let j = 0; j < daysAhead; j++) {
		const day = currentDate.clone().add(j, "days");
		if (day.clone().format("MM") === currentDate.format("MM")) {
			result[0].days.push({
				weekday: day.format("ddd"),
				date: day,
			});
			continue;
		}
		result[1].days.push({
			weekday: day.format("ddd"),
			date: day,
		});
	}

	if (result[0].days.length >= daysAhead) {
		return [result[0]];
	}

	return result;
}

export function haveCommonElements(arr1: string[], arr2: string[]) {
	const set1 = new Set(arr1);
	for (let elem of arr2) {
		if (set1.has(elem)) {
			return true;
		}
	}
	return false;
}

export function isSearchTextMatch(obj: IMember, searchText: string) {
	const { group, profilePicture, ...searchObj } = obj;
	let target = Object.values(searchObj)
		.map((e: any) => "" + e)
		.join();
	target += obj?.firstName + " " + obj?.lastName;
	const serachRegex = new RegExp(searchText.toLocaleLowerCase());
	return serachRegex.test(target.toLocaleLowerCase());
}

export function sortTeamGroups(
	memberA: IMember,
	memberB: IMember,
	loggedMember: IMember
) {
	const hasTeamA = memberA.teams.includes(loggedMember.teams[0]);
	const hasTeamB = memberB.teams.includes(loggedMember.teams[0]);
	const hasGroupA = memberA.groups.includes(loggedMember.groups[0]);
	const hasGroupB = memberB.groups.includes(loggedMember.groups[0]);

	if (hasGroupA && !hasGroupB) {
		return -1;
	} else if (!hasGroupA && hasGroupB) {
		return 1;
	} else if (hasTeamA && !hasTeamB) {
		return -1;
	} else if (!hasTeamA && hasTeamB) {
		return 1;
	} else {
		return 0;
	}
}


export function sortByGroups(
	memberA: IMember,
	memberB: IMember,
	loggedMember: IMember
) {
	const hasGroupA = memberA.groups.includes(loggedMember.groups[0]);
	const hasGroupB = memberB.groups.includes(loggedMember.groups[0]);
	const groupA = parseInt(memberA.groups[0]) || 0
	const groupB = parseInt(memberB.groups[0]) || 0

	if (hasGroupA && !hasGroupB) {
		return -1;
	} else if (!hasGroupA && hasGroupB) {
		return 1;
	} else {
		return groupA < groupB ? -1 : 1;
	}
}


export function sortGroups(
	memberA: IMember,
	memberB: IMember,
	loggedMember: IMember
) {
	const hasGroupA = loggedMember.groups.includes(memberA.groups[0]);
	const hasGroupB = loggedMember.groups.includes(memberB.groups[0]);

	if (hasGroupA && !hasGroupB) {
		return -1;
	} else if (!hasGroupA && hasGroupB) {
		return 1;
	} else {
		return 0;
	}
}


export const getFullName = (firstName: string, lastName: string): string =>
	`${firstName ?? ""} ${lastName ?? ""}`.trim() || "";

export const getGroupType = (division: string) =>
	(division && division === "Cityhopper" && "unit") || "group";

export const getLocation = (
	timeslot: string,
	mSchedules: ISchedule[],
	lang: string,
	locs: ILoc[]
): string => {
	if (!mSchedules || !mSchedules.length) return "-";
	const filteredSchedule: ISchedule = mSchedules.filter(
		(ms) => ms.timeslot === timeslot
	)[0];
	if (filteredSchedule && filteredSchedule.loc) {
		//@ts-ignore
		const locationName = locs.filter(
			(l: ILoc) => l.id === filteredSchedule.loc
		)[0]?.[lang];
		return locationName;
	}
	return "-";
};