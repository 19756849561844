import { configureStore } from "@reduxjs/toolkit";
import configReducer from "./features/configSlice";
import memberReducer from "./features/memberSlice";

export const store = configureStore({
	reducer: {
		config: configReducer,
		member: memberReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
