import { FunctionComponent, useState } from "react";
import { useAppSelector } from "../../store/hooks";

interface IProfileImageProps {
    imageUrl: string;
    altText: string;
}

export const ProfileImage: FunctionComponent<IProfileImageProps> = ({
    imageUrl,
    altText,
}) => {
    return (
        <>
            <ProfileImageInner
                key={imageUrl}
                imageUrl={imageUrl}
                altText={altText}
            />
        </>
    );
};

const ProfileImageInner: FunctionComponent<IProfileImageProps> = ({
    imageUrl,
    altText,
}) => {
    const { magazineId } = useAppSelector((state) => state.config);

    const [imageError, setImageError] = useState(imageUrl ? false : true);

    const handleImageError = () => setImageError(true);

    const imageSrc = imageError
        ? "/images/avatar-fallback.png"
        : `/api/members/images?magazine=${magazineId}&imgUrl=${encodeURIComponent(imageUrl)}`;

    return (
        <>
            <img
                src={imageSrc}
                alt={altText}
                onError={handleImageError}
                className="profile-image"
            />
        </>
    );
};

export default ProfileImage;
