import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { formatDate, getDays } from "../utils";
import EditScheduleCard from "./EditScheduleCard";
import { useAppSelector } from "../../store/hooks";
import { ISchedule } from "../../interfaces/schedule.interface";
import * as Api from "../../services/Api";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

interface ISchedulesProps {
	uid: string;
}

const Schedules: FunctionComponent<ISchedulesProps> = ({ uid }) => {
	const twoWeeks = getDays(14);
	const [schedules, setSchedules] = useState<ISchedule[]>([]);
	const {
		config: { token, magazineId },
		member: { member },
	} = useAppSelector((state) => state);

	const { t } = useTranslation();

	async function getSchedules(signal: AbortSignal) {
		try {
			const schedules = await Api.getSchedulesByUid(
				token,
				magazineId,
				uid,
				signal
			);
			setSchedules(schedules);
		} catch (error) {
			// TODO: Global handle error with redux
			console.error(error);
		}
	}

	const selectSchedules = (date: Dayjs): ISchedule[] =>
		schedules?.filter((s) => s.date === formatDate(date)) || [];

	useEffect(() => {
		if (!member.uid) return;
		const controller = new AbortController();
		getSchedules(controller.signal);
		return () => {
			controller.abort();
		};
	}, [uid, member]);

	return (
		<div className="schedules-container">
			{twoWeeks.map((m) => {
				return (
					<Fragment key={m.monthName}>
						<div className="month-name">{t(m.monthName)}</div>
						<div className="schedule-cards">
							{m.days.map((day, index) => (
								<Fragment key={index}>
									<EditScheduleCard
										day={day}
										uid={uid}
										setSchedules={setSchedules}
										cardSchedules={selectSchedules(day.date)}
									/>
								</Fragment>
							))}
						</div>
					</Fragment>
				);
			})}
		</div>
	);
};

export default Schedules;
