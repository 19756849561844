import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useLocation } from "react-router-dom";
import EditSchedule from "./components/edit-schedule";
import AllSchedules from "./components/full/AllSchedules";
import Full from "./components/full/Full";
import Inline from "./components/inline/Inline";
import ManageProfiles from "./components/manage-profiles";
import MemberSchedule from "./components/member-schedule";
import { getConfig, saveBasicConfig } from "./store/features/configSlice";
import { getMember } from "./store/features/memberSlice";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import "./styles/App.scss";
import "./styles/App.dark.scss";

function App() {
	let location = useLocation();
	let query = new URLSearchParams(location.search);
	const { i18n } = useTranslation();
	const lang = (query.get("lang") || "en") as "en" | "nl";
	const token = query.get("token");
	const magazineId = query.get("magazine");
	const [error, setError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");

	const dispatch = useAppDispatch();
	const {
		config: { loading },
		member: { getMemberLoading },
	} = useAppSelector((state) => state);

	async function loadMagazine(signal: AbortSignal) {
		try {
			dispatch(
				saveBasicConfig({
					token: token ?? "",
					magazineId: magazineId ?? "",
					queryString: `?token=${token}&magazine=${magazineId}&lang=${lang}`,
					lang,
				})
			);
			const result = await dispatch(
				getConfig({
					token: token ?? "",
					magazineId: magazineId ?? "",
					signal,
				})
			);
			if (!result.payload) {
				setError(true);
				setErrorMessage(`no magazine found or no magazine id provided`);
				return;
			}
			const user = await dispatch(
				getMember({
					token: token ?? "",
					magazineId: magazineId ?? "",
					signal,
				})
			);
			if (!user.payload) {
				setError(true);
				setErrorMessage("No manager found");
				return;
			}

			setError(false);
			setErrorMessage("");
		} catch (error) {
			setError(true);
			setErrorMessage("get Magazine failed, error: invalid token");
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		loadMagazine(controller.signal);
		i18n.changeLanguage(lang);
		return () => {
			controller.abort();
		};
	}, []);

	if (error) {
		const errorText = !true ? "no magazine found" : errorMessage;
		return <p>{errorText}</p>;
	}

	if (loading || getMemberLoading) {
		return (
			<div className="h-100 w-100 d-flex align-items-center justify-content-center">
				Getting magazine details...
			</div>
		);
	}

	return (
		<Switch>
			<Route exact path="/">
				<Inline />
			</Route>
			<Route exact path="/full">
				<Full />
			</Route>
			<Route exact path="/all-schedules">
				<AllSchedules />
			</Route>
			<Route path="/edit-schedule/:uid">
				<EditSchedule />
			</Route>
			<Route path="/member-schedule/:uid">
				<MemberSchedule />
			</Route>
			<Route exact path="/manage-profiles">
				<ManageProfiles />
			</Route>
		</Switch>
	);
}

export default App;
